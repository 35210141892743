<template>
  <section>
    <section class="mt-4">
      <div class="container">
        <PageMetadata />
        <loading-overlay
          :active="isLoading"
          is-full-page="true"
          loader="bars"
        />
        <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
          {{ flashAlert.msg }}</flash-alert
        >
        <div class="mb-5">
          <h1 class="pb-0 mb-4 px-0">{{ getMetaDetails.page_h1 }}</h1>
          <div
            class="content_below_heading"
            v-if="!!getMetaDetails.description"
            v-html="getMetaDetails.description"
          ></div>
        </div>
      </div>
    </section>
    <section class="" id="eventplanner-page">
      <div class="container">
        <div
          class="table-responsive mb-4"
          v-if="isLoggedIn && savedPP.length > 0"
        >
          <h3 id="product-planner-tab" class="sub-header pb-0">
            Saved Product Planner
          </h3>
          <table class="table table-striped table-bordered text-center">
            <thead class="bg-grey">
              <tr>
                <th>Event Name</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(ppl_quote, index) in savedPP" :key="index">
                <td>
                  <a :href="`/edit-product-planner/${ppl_quote.id}`">{{
                    ppl_quote.event_name
                  }}</a>
                </td>
                <td>{{ formatDateWithDash(ppl_quote.event_date) }}</td>
                <td>
                  <a :href="`/edit-product-planner/${ppl_quote.id}`"
                    ><i class="fa-solid fa-pen-to-square"></i
                  ></a>
                  <delete-product-planner
                    :itemId="ppl_quote.id"
                  ></delete-product-planner>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row" v-else>
          <p class="alert alert-danger">Please login to view content</p>
        </div>
      </div>
    </section>
  </section>
</template>
<script>
import { misc } from "@/mixins";
import axios from "axios";
import { DeleteProductPlanner, PageMetadata } from "@/components";

export default {
  name: "ProductPlannerList",
  mixins: [misc],
  components: {
    DeleteProductPlanner,
    PageMetadata,
    //   VueRecaptcha
  },
  data() {
    return {
      isLoading: false,
      flowerStyling: {
        position: "absolute",
        width: "100%",
      },
      savedPP: [],
      programSettings: {},
    };
  },
  mounted() {
    // this.clonePlannerFields = this.plannerFields
    localStorage.setItem("currentPage", "product-planner-list");
    this.getProgramSettings();
    this.fetchMetaDetails();
    this.fetchSavedPP();
    this.emitter.on("deletedPP", () => {
      this.PPDeleted();
    });
  },
  methods: {
    PPDeleted() {
      this.fetchSavedPP();
    },
    getProgramSettings() {
      axios
        .get(process.env.VUE_APP_API_BASE + "auth/getProgramSettings", {
          headers: {
            "Content-type": "application/json;multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data.error === false) {
            this.programSettings = response.data.settings;
            let maxDate = new Date();
            let numberOfDaysToAdd = this.programSettings.days_ahead;
            maxDate.setDate(maxDate.getDate() + numberOfDaysToAdd);
            this.maxDate = maxDate;
          }
        });
    },
    getDefaultImage(productType) {
      let images = localStorage.getItem("productTypes");
      images = JSON.parse(images);
      if (productType == "" || productType == null) {
        return "";
      }
      for (var i = 0; i < images.length; i++) {
        if (images[i]["id"] == productType) return images[i]["default_image"];
      }

      return "";
    },

    fetchSavedPP() {
      try {
        axios
          .get(process.env.VUE_APP_API_BASE + "auth/getSavedProductPlanners", {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.savedPP = response.data.product_planners;
          });
      } catch {
        console.log("Something went wrong");
      }
    },
  },
};
</script>
<style scoped>
.md-form {
  position: relative;
}

span.warn {
  position: absolute;
  /* float: right; */
  margin-top: -29px;
  margin-left: 458px;
}
.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}
body .select2-container--default .select2-selection--single {
  border: 1px solid #ced4da;
  height: 38px;
}
.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}
body
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: normal;
  height: 38px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
body
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: normal;
  height: 38px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.event-planner {
  border: 2px solid #e6e5e5;
  margin-top: 22px;
  padding: 10px;
}
.mt_top {
  display: block;
  margin: 20px 15px 20px 0px;
}
.btn-success {
  margin-right: 5px;
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.event-planner .table td:nth-child(4) {
  width: 8%;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.table thead th,
.table > :not(:last-child) > :last-child > * {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  border-bottom-color: #dee2e6;
}
#btfrom {
  border: 2px solid #9f9f9f;
  border-radius: inherit;
  background: inherit;
  color: #797979;
  padding: 8px 25px;
}
#btfrom:hover {
  border: 2px solid #9f9f9f;
  background: #9f9f9f;
  color: #fff;
}
.red-asterick {
  color: red;
}
.error-text {
  color: red;
}
</style>